import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import CircleExclamation from 'assets/icons/circle_exclamation.svg'
import { buildTranslate } from 'locales'
import React from 'react'
import { Container } from 'react-bootstrap-five'

const t = buildTranslate('errors')

type PropsT = {
  title?: string
  message?: string
  dataTestId?: string
}

const ErrorPage: React.FC<PropsT> = ({
  title = t('error_page_title'),
  message = t('error_page_message'),
  dataTestId = 'error-page',
}: PropsT) => {
  const [css] = useStyletron()

  return (
    <div
      data-testid={dataTestId}
      className={`grey-05-bkg pb-xxl grey-20-border ${css({
        height: '100vh',
      })}`}
    >
      <Container className="pt-xl">
        <div className="text-center">
          <div>
            <img
              src={CircleExclamation}
              alt="error"
              className={`${css({
                width: '48px',
                height: '48px',
              })}`}
            />
          </div>
          <Text.Header4 bold className="mt-m mb-xs grey-90">
            {title}
          </Text.Header4>
          <Text.BodyText3 className="grey70" tag="p">
            {message}
          </Text.BodyText3>
        </div>
      </Container>
    </div>
  )
}

export default ErrorPage
