import PopoverMenu from 'components/PopoverMenu'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import React from 'react'
import { Button } from 'react-bootstrap-five'
import { push } from 'react-router-redux'

type Props = {
  onDelete: () => void
  onView: () => void
  smartLinkId: number
}

const t = buildTranslate('smart_links.overview.actions')

const SmartLinkActionMenu = ({ onDelete, onView, smartLinkId }: Props) => {
  const dispatch = useDispatch()
  const onEdit = () => {
    dispatch(push(`/smart_links_overview/edit/${smartLinkId}`))
  }

  return (
    <>
      <PopoverMenu dismissOnClickOutside closeOnClick>
        <Button
          className="d-block grey-90 pr-m"
          onClick={onEdit}
          key={t('edit')}
          variant="link"
          data-testid="edit-smart-link-button"
        >
          {t('edit')}
        </Button>
        <Button
          className="d-block grey-90 pr-m"
          key={t('view_details')}
          variant="link"
          onClick={onView}
          data-testid="view-smart-link-button"
        >
          {t('view_details')}
        </Button>
        <Button
          className="d-block grey-90 pr-m"
          key={t('delete')}
          variant="link"
          onClick={onDelete}
          data-testid="delete-smart-link-button"
        >
          {t('delete')}
        </Button>
      </PopoverMenu>
    </>
  )
}

export default SmartLinkActionMenu
