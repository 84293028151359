import { Button } from '@thanx/uikit/button'
import { Text } from '@thanx/uikit/text'
import palette from 'constants/palette'
import { buildTranslate } from 'locales'
import React from 'react'
import { Container } from 'react-bootstrap-five'
import { smartLinksHelpUrl } from 'utilities/urlUtils'

type Props = {
  onAddNewSmartLinkClick: () => void
}
const t = buildTranslate('smart_links.overview')

const NoContent = ({ onAddNewSmartLinkClick }: Props) => {
  return (
    <Container className="flex justify-center flex-col items-center w-[600px] text-center mt-xl">
      <Text.Header4 className="m-0 mr-xl mb-m mb-sm-0" bold>
        {t('no_smart_links_available_title')}
      </Text.Header4>
      <div className="mt-xs">
        <Text.BodyText3 color={palette.grey70} className="m-0 mb-m mb-sm-0">
          {t('description')}
        </Text.BodyText3>
        <span className="ml-xs">
          <a
            href={smartLinksHelpUrl}
            className="body-text-3"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('learn_more')}
          </a>
        </span>
      </div>
      <Button
        kind="secondary"
        onClick={onAddNewSmartLinkClick}
        className="mr-s mt-m mb-s mb-sm-0"
      >
        {t('add_a_new_smart_link')}
      </Button>
    </Container>
  )
}

export default NoContent
