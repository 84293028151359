import { AppLink } from 'scenes/Cms/Settings/SmartLinks/LinkGenerationForm/AppFields'
import { WebOrderingLocation } from 'scenes/Cms/Settings/SmartLinks/LinkGenerationForm/WebFields'
import { WebFallback } from 'scenes/Cms/Settings/SmartLinks/LinkGenerationForm/WebFields'

export type SmartLink = {
  id: number
  name: string
  url: string
  merchantId: number
  state: string
  createdAt: string
  updatedAt: string
  appLink: string
  appLinkCategory: string
  appLinkProduct: string
  desktopFallback: string
  desktopCustomUrl: string
  desktopWebOrderingLocation: string
  desktopWebOrderingCategory: string
  desktopWebOrderingProduct: string
  mobileFallback: string
  mobileCustomUrl: string
  mobileWebOrderingLocation: string
  mobileWebOrderingCategory: string
  mobileWebOrderingProduct: string
}

export function transformToCamelCase(smartLink: {
  id: number
  name: string
  url: string
  merchant_id: number
  state: string
  created_at: string
  updated_at: string
  app_link: string
  app_link_category: string
  app_link_product: string
  desktop_fallback: string
  desktop_custom_url: string
  desktop_web_ordering_location: string
  desktop_web_ordering_category: string
  desktop_web_ordering_product: string
  mobile_fallback: string
  mobile_custom_url: string
  mobile_web_ordering_location: string
  mobile_web_ordering_category: string
  mobile_web_ordering_product: string
}): SmartLink {
  return {
    id: smartLink.id,
    name: smartLink.name,
    url: smartLink.url,
    merchantId: smartLink.merchant_id,
    state: smartLink.state,
    createdAt: smartLink.created_at,
    updatedAt: smartLink.updated_at,
    appLink: smartLink.app_link,
    appLinkCategory: smartLink.app_link_category,
    appLinkProduct: smartLink.app_link_product,
    desktopFallback: smartLink.desktop_fallback,
    desktopCustomUrl: smartLink.desktop_custom_url,
    desktopWebOrderingLocation: smartLink.desktop_web_ordering_location,
    desktopWebOrderingCategory: smartLink.desktop_web_ordering_category,
    desktopWebOrderingProduct: smartLink.desktop_web_ordering_product,
    mobileFallback: smartLink.mobile_fallback,
    mobileCustomUrl: smartLink.mobile_custom_url,
    mobileWebOrderingLocation: smartLink.mobile_web_ordering_location,
    mobileWebOrderingCategory: smartLink.mobile_web_ordering_category,
    mobileWebOrderingProduct: smartLink.mobile_web_ordering_product,
  }
}

export type FormModel = {
  name?: string
  appLink?: AppLink
  appLinkCategory?: string
  appLinkProduct?: string

  desktopFallback?: WebFallback
  desktopCustomUrl?: string
  desktopWebOrderingLocation?: WebOrderingLocation
  desktopWebOrderingCategory?: string
  desktopWebOrderingProduct?: string

  mobileFallback?: WebFallback
  mobileCustomUrl?: string
  mobileWebOrderingLocation?: WebOrderingLocation
  mobileWebOrderingCategory?: string
  mobileWebOrderingProduct?: string
}

export const transformSmartLinkToFormModel = (
  smartLink: SmartLink
): FormModel => {
  return {
    name: smartLink.name,
    appLink: smartLink.appLink as AppLink,
    appLinkCategory: smartLink.appLinkCategory,
    appLinkProduct: smartLink.appLinkProduct,

    desktopFallback: smartLink.desktopFallback as WebFallback,
    desktopCustomUrl: smartLink.desktopCustomUrl,
    desktopWebOrderingLocation:
      smartLink.desktopWebOrderingLocation as WebOrderingLocation,
    desktopWebOrderingCategory: smartLink.desktopWebOrderingCategory,
    desktopWebOrderingProduct: smartLink.desktopWebOrderingProduct,

    mobileFallback: smartLink.mobileFallback as WebFallback,
    mobileCustomUrl: smartLink.mobileCustomUrl,
    mobileWebOrderingLocation:
      smartLink.mobileWebOrderingLocation as WebOrderingLocation,
    mobileWebOrderingCategory: smartLink.mobileWebOrderingCategory,
    mobileWebOrderingProduct: smartLink.mobileWebOrderingProduct,
  }
}

export const emptyFormModel: FormModel = {
  name: '',
  appLink: undefined,
  appLinkCategory: undefined,
  appLinkProduct: undefined,

  desktopFallback: undefined,
  desktopCustomUrl: undefined,
  desktopWebOrderingLocation: undefined,
  desktopWebOrderingCategory: undefined,
  desktopWebOrderingProduct: undefined,

  mobileFallback: undefined,
  mobileCustomUrl: undefined,
  mobileWebOrderingLocation: undefined,
  mobileWebOrderingCategory: undefined,
  mobileWebOrderingProduct: undefined,
}
