import { FormsySelect } from '@thanx/uikit/select'
import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import { buildTranslate } from 'locales'
import { FormModel } from 'models/smartLink'
import React, { useMemo } from 'react'

export type AppLink =
  | 'about'
  | 'account'
  | 'homePage'
  | 'locations'
  | 'notifications'
  | 'nps'
  | 'orderHistory'
  | 'orderingMenu'
  | 'productDetails'
  | 'registeredCards'
  | 'rewards'
  | 'support'
  | 'earn'

export const defaultAppLinks: AppLink[] = [
  'about',
  'account',
  'homePage',
  'locations',
  'notifications',
  'nps',
  'registeredCards',
  'rewards',
  'support',
  'earn',
]

const t = buildTranslate('cms.settings.smart_links.app_link_and_fallbacks')
const smartLinksT = buildTranslate('cms.settings.smart_links')

type Props = {
  formModel: FormModel
  menuCategoryOptions: { label: string; value: string }[]
  productOptions: { label: string; value: string }[]
  hideTitle?: boolean
  initialFormModel: FormModel
}

function AppFields(props: Props) {
  const {
    formModel,
    menuCategoryOptions,
    productOptions,
    hideTitle = false,
    initialFormModel = {},
  } = props

  const { app_ordering_enabled } = useCurrentMerchant() || {}

  const appLinkOptions = useMemo(() => {
    const links = [...defaultAppLinks]

    if (app_ordering_enabled) {
      links.push('orderHistory')

      if (menuCategoryOptions.length) {
        links.push('orderingMenu')

        if (productOptions.length) {
          links.push('productDetails')
        }
      }
    }

    return links.map(link => ({
      label: t(`labels.${link}`),
      value: link,
    }))
  }, [app_ordering_enabled, menuCategoryOptions, productOptions])

  return (
    <>
      {!hideTitle && <Text.Header4 className="mb-m">{t('title')}</Text.Header4>}
      <FormsySelect
        label={t('app_link_label')}
        name="appLink"
        options={appLinkOptions}
        value={initialFormModel.appLink || ''}
        validations={{
          isRequired: true,
        }}
        validationErrors={{
          isRequired: smartLinksT('required'),
        }}
      />
      <Switch condition={formModel.appLink === 'orderingMenu'}>
        <FormsySelect
          className="mt-m"
          label={t('app_link_category_label')}
          name="appLinkCategory"
          value={initialFormModel.appLinkCategory || ''}
          description={t('app_link_category_instructions')}
          options={menuCategoryOptions}
          validations={{
            isRequired: true,
          }}
          validationErrors={{
            isRequired: smartLinksT('required'),
          }}
        />
      </Switch>
      <Switch condition={formModel.appLink === 'productDetails'}>
        <FormsySelect
          className="mt-m"
          label={t('app_link_product_label')}
          name="appLinkProduct"
          value={initialFormModel.appLinkProduct || ''}
          description={t('app_link_product_instructions')}
          options={productOptions}
          validations={{
            isRequired: true,
          }}
          validationErrors={{
            isRequired: smartLinksT('required'),
          }}
        />
      </Switch>
    </>
  )
}

export default AppFields
