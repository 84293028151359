import { Switch } from '@thanx/uikit/switch'
import { smartLinksApi } from 'api/smartLink'
import ErrorPage from 'components/ErrorPage'
import Spinner from 'components/Spinner'
import useDispatch from 'hooks/useDispatch'
import { SmartLink } from 'models/smartLink'
import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { push } from 'react-router-redux'
import { transformSmartLinkPayloadToSmartLink } from 'utilities/smartLink'
import CreateSmartLink from '../CreateSmartLink'

type Props = RouteComponentProps<{ id: string }>

const EditSmartLink = (props: Props) => {
  const { id } = props.match.params
  const dispatch = useDispatch()
  const {
    data: { smart_link: smartLinkPayload } = {
      smart_link: null,
    },
    isLoading: isLoadingSmartLink,
    isError: isErrorSmartLink,
    isSuccess: isSuccessSmartLink,
  } = smartLinksApi.useGetSmartLinkQuery(id)

  const [smartLink, setSmartLink] = useState<SmartLink | null>(null)

  useEffect(() => {
    if (!smartLinkPayload && isSuccessSmartLink) {
      setSmartLink(null)
      dispatch(push('/smart_links_overview'))
      return
    }
    if (smartLinkPayload && isSuccessSmartLink) {
      setSmartLink(transformSmartLinkPayloadToSmartLink(smartLinkPayload))
    }
  }, [smartLinkPayload, isSuccessSmartLink])

  const spinner = (
    <Spinner
      data-testid="spinner"
      isLoading={true}
      size="4x"
      className="d-flex justify-content-center pt-xl"
    />
  )

  let content: React.ReactNode | null = null
  let fallback: React.ReactNode | null = null
  if (isLoadingSmartLink) {
    fallback = spinner
  }
  if (isErrorSmartLink) {
    fallback = <ErrorPage data-testid="error-page" />
  }
  if (isSuccessSmartLink) {
    content = <CreateSmartLink isEditMode={true} smartLink={smartLink} />
  }

  return (
    <Switch condition={isSuccessSmartLink} fallback={fallback}>
      {content}
    </Switch>
  )
}

export default EditSmartLink
