import InnerTabs from 'components/Tabs/InnerTabs'
import useFlag from 'hooks/useFlag'
import { buildTranslate } from 'locales'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentMerchantUser } from 'selectors/merchantUser'
import Analytics from './Analytics'
import OrderingConfiguration from './OrderingConfiguration'
import ReceiptSettings from './ReceiptSettings'
import SmartLinks from './SmartLinks'
import TermsAndPolicies from './TermsAndPolicies'

export enum Tabs {
  Locations = 'locations',
  SmartLinks = 'smart_links',
  OrderingConfiguration = 'ordering_configuration',
  ReceiptSettings = 'receipt_settings',
  TermsAndPolicies = 'terms_and_policies',
  Analytics = 'analytics',
}

const t = buildTranslate('cms')

const Settings = () => {
  const smartLinksFlagEnabled = useFlag('use-smart-links', false)
  const user = useSelector(selectCurrentMerchantUser)
  const isAdmin = user?.is_admin || false
  const customizableLegalDocsFlag = useFlag('mx-customizable-legal-docs', false)
  const isCustomizableRoutesEnabled = customizableLegalDocsFlag && isAdmin
  const isReuseableSmartLinksEnabled = useFlag('use-smart-links', false)

  const smartLinksTab = {
    key: Tabs.SmartLinks,
    component: <SmartLinks />,
    title: t('settings.smart_links.title'),
  }
  const tabs = [
    ...(smartLinksFlagEnabled && !isReuseableSmartLinksEnabled
      ? [smartLinksTab]
      : []),
    {
      key: Tabs.OrderingConfiguration,
      component: <OrderingConfiguration />,
      title: t('settings.ordering_configuration.title'),
    },
    {
      key: Tabs.ReceiptSettings,
      component: <ReceiptSettings />,
      title: t('settings.receipt_settings.title'),
    },
    ...(isCustomizableRoutesEnabled
      ? [
          {
            key: Tabs.TermsAndPolicies,
            component: <TermsAndPolicies />,
            title: t('settings.terms_and_policies.title'),
          },
        ]
      : []),
    {
      key: Tabs.Analytics,
      component: <Analytics />,
      title: t('settings.analytics.tab'),
    },
  ]

  return <InnerTabs tabs={tabs} />
}

export default Settings
