import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import { buildTranslate } from 'locales'
import React from 'react'
import { Container } from 'react-bootstrap-five'
import LookerFooter from 'scenes/LookerEmbed/components/LookerFooter'
import LookerIframe from 'scenes/LookerEmbed/LookerIframe'

const t = buildTranslate('stored_value')
const tError = buildTranslate('errors')

const StoredValueActivity = ({
  canAccessAnsaReports,
  merchant,
  storedValueActivityPath,
  storedValueActivityHeight,
}: any) => {
  const [css] = useStyletron()

  return (
    <div className="pb-l grey-05-bkg">
      <div className="container pl-0 pr-0">
        <Text.Header3 className="mt-l mb-xl" tag="div">
          {t('tabs.activity')}
        </Text.Header3>
        <Container
          className={`px-0 ${css({
            minHeight: `${storedValueActivityHeight + 300}px`,
          })}`}
        >
          <Switch
            condition={canAccessAnsaReports}
            fallback={
              <Text.BodyText3 className="mb-xl">
                {tError('forbidden_title')}
              </Text.BodyText3>
            }
          >
            <LookerIframe
              path={storedValueActivityPath}
              merchant={merchant}
              height={`${storedValueActivityHeight}px`}
            />
            <LookerFooter className="my-xl" />
          </Switch>
        </Container>
      </div>
    </div>
  )
}

export default StoredValueActivity
