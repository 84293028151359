import { api } from './index'

export type SmartLinkPayload = {
  id: number
  name: string
  url: string
  merchant_id: number
  state: string
  created_at: string
  updated_at: string
  app_link: string
  app_link_category: string
  app_link_product: string
  desktop_fallback: string
  desktop_custom_url: string
  desktop_web_ordering_location: string
  desktop_web_ordering_category: string
  desktop_web_ordering_product: string
  mobile_fallback: string
  mobile_custom_url: string
  mobile_web_ordering_location: string
  mobile_web_ordering_category: string
  mobile_web_ordering_product: string
}

type GetSmartLinksResponse = { smart_links: SmartLinkPayload[] }

type GetSmartLinkResponse = { smart_link: SmartLinkPayload }

export type RequestSmartLinkPayload = {
  name: string
  url: string
  app_link?: string | null
  app_link_category?: string | null
  app_link_product?: string | null
  desktop_fallback?: string | null
  desktop_custom_url?: string | null
  desktop_web_ordering_location?: string | null
  desktop_web_ordering_category?: string | null
  desktop_web_ordering_product?: string | null
  mobile_fallback?: string | null
  mobile_custom_url?: string | null
  mobile_web_ordering_location?: string | null
  mobile_web_ordering_category?: string | null
  mobile_web_ordering_product?: string | null
}

export type CreateSmartLinkPayload = {
  smart_link: RequestSmartLinkPayload
}

export type PublishSmartLinkPayload = {} & { id: number }

export type UpdateSmartLinkPayload = RequestSmartLinkPayload & { id: number }

export const smartLinksApi = api.injectEndpoints({
  endpoints: builder => ({
    getSmartLinks: builder.query<GetSmartLinksResponse, void>({
      query: () => {
        return {
          url: 'smart_links',
        }
      },
      providesTags: ['SmartLink'],
    }),

    getSmartLink: builder.query<GetSmartLinkResponse, string>({
      query: id => ({ url: `smart_links/${id}` }),
      providesTags: (_result, _error, id) => [{ type: 'SmartLink', id }],
    }),

    createSmartLink: builder.mutation<
      GetSmartLinkResponse,
      CreateSmartLinkPayload
    >({
      query: payload => ({
        url: 'smart_links',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['SmartLink'],
    }),

    updateSmartLink: builder.mutation<
      GetSmartLinkResponse,
      UpdateSmartLinkPayload
    >({
      query: patch => {
        const { id, ...rest } = patch
        const emptyPayload = {
          name: null,
          url: null,
          app_link: null,
          app_link_category: null,
          app_link_product: null,
          desktop_fallback: null,
          desktop_custom_url: null,
          desktop_web_ordering_location: null,
          desktop_web_ordering_category: null,
          desktop_web_ordering_product: null,
          mobile_fallback: null,
          mobile_custom_url: null,
          mobile_web_ordering_location: null,
          mobile_web_ordering_category: null,
          mobile_web_ordering_product: null,
        }

        const smartLinkBody = {
          ...emptyPayload,
          ...rest,
        }

        return {
          url: `smart_links/${id}`,
          method: 'PATCH',
          body: {
            smart_link: smartLinkBody,
          },
        }
      },
      invalidatesTags: ['SmartLink'],
    }),

    archiveSmartLink: builder.mutation<void, number>({
      query: id => ({
        url: `smart_links/${id}/archive`,
        method: 'POST',
      }),
      invalidatesTags: ['SmartLink'],
    }),
  }),
})
