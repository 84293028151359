import { Button, Kind, Size } from '@thanx/uikit/button'
import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { formatDateString } from 'components/PointsMultiplierCard/helpers'
import Spinner from 'components/Spinner'
import palette from 'constants/palette'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import useDispatch from 'hooks/useDispatch'
import useProducts from 'hooks/useProducts'
import { buildTranslate } from 'locales'
import { SmartLink } from 'models/smartLink'
import React, { useMemo } from 'react'
import { Button as BsButton, Modal } from 'react-bootstrap'
import { Translate } from 'react-redux-i18n'
import { push } from 'react-router-redux'
import { useUniqueMenuCategories } from 'scenes/Cms/Content/AppHomePage/hooks'
import { defaultAppLinks } from 'scenes/Cms/Settings/SmartLinks/LinkGenerationForm/AppFields'
import {
  getWebFallbackOptions,
  WebFallback,
} from 'scenes/Cms/Settings/SmartLinks/LinkGenerationForm/WebFields'
import Content from 'scenes/Cms/Settings/TermsAndPolicies/components/Content'
import { useStyletron } from 'styletron-react'

const t = buildTranslate('cms.settings.smart_links.app_link_and_fallbacks')

const getLabelByValue = (
  value: string,
  linkOptions: { label: string; value: string }[]
) => {
  const option = linkOptions.find(option => option.value === value)
  return option ? option.label : null
}

export type Props = {
  smartLink: SmartLink
  onCopyUrlClick: () => Promise<void>
  show: boolean
  onHide: () => void
  availableFallbacks: WebFallback[]
}

const SmartLinkViewModal: React.FC<Props> = ({
  smartLink,
  onCopyUrlClick,
  show,
  onHide,
  availableFallbacks,
}) => {
  const [css] = useStyletron()
  const dispatch = useDispatch()

  const { products, isLoading: productsLoading } = useProducts()
  const productOptions = (products || []).map(product => ({
    label: product.name,
    value: product.name.toLowerCase().replace(/\s+/g, '+'),
  }))
  const { menuCategories, isLoading: menuCategoriesLoading } =
    useUniqueMenuCategories()
  const merchant = useCurrentMerchant()
  const app_ordering_enabled = merchant?.app_ordering_enabled

  const menuCategoryOptions = (menuCategories || []).map(category => ({
    label: category.name,
    value: category.slug,
  }))
  const webFallbackOptions = getWebFallbackOptions(merchant)

  const fallbackOptions = webFallbackOptions.filter(o =>
    availableFallbacks.includes(o.value)
  )
  const isLoading = productsLoading || menuCategoriesLoading

  let spinner: React.ReactNode | null = null
  if (isLoading) {
    spinner = (
      <Spinner
        className="text-center margin-top-huge margin-bottom-huge"
        size="4x"
        isLoading={true}
      />
    )
  }

  const appLinkOptions = useMemo(() => {
    const links = [...defaultAppLinks]

    if (app_ordering_enabled) {
      links.push('orderHistory')

      if (menuCategoryOptions.length) {
        links.push('orderingMenu')

        if (productOptions.length) {
          links.push('productDetails')
        }
      }
    }

    return links.map(link => ({
      label: t(`labels.${link}`),
      value: link,
    }))
  }, [app_ordering_enabled, menuCategoryOptions, productOptions])

  const exit = () => {
    onHide()
  }

  const onEdit = async () => {
    dispatch(push(`/smart_links_overview/edit/${smartLink.id}`))
    onHide()
  }

  return (
    <Modal className={`fs-unmask `} show={show} onHide={onHide} zIndex={1000}>
      <Modal.Body>
        <div className="clearfix">
          <div className="pull-right">
            <BsButton
              onClick={onHide}
              className="btn-borderless padding-right-none"
            >
              <span className="font-size-40 line-height-30 grey-60 light">
                &times;
              </span>
            </BsButton>
          </div>
        </div>
        <Switch condition={!isLoading} fallback={spinner}>
          <Text.Header3 color={palette.grey90} bold className="mt-0">
            {smartLink.name}
          </Text.Header3>
          <>
            <Content
              enable={smartLink.url !== null}
              key={smartLink.url}
              title={'smart_links.overview.view_modal.url'}
              content={smartLink.url}
              titleClassName={`mt-xl ${css({
                color: palette.grey90,
              })}`}
              contentClassName={`${css({
                wordBreak: 'break-all',
                color: palette.grey70,
              })}`}
            />
            <Content
              enable={smartLink.appLink !== null}
              key={smartLink.appLink}
              title={
                'smart_links.overview.view_modal.what_page_you_want_to_link_to_in_the_app'
              }
              content={getLabelByValue(smartLink.appLink, appLinkOptions) || ''}
              titleClassName={`${css({
                color: palette.grey90,
              })}`}
              contentClassName={`${css({
                wordBreak: 'break-all',
                color: palette.grey70,
              })}`}
            />
            <Content
              enable={smartLink.appLinkCategory !== null}
              key={smartLink.appLinkCategory}
              title={
                'smart_links.overview.view_modal.which_ordering_menu_you_want_to_link_to'
              }
              content={
                getLabelByValue(
                  smartLink.appLinkCategory,
                  menuCategoryOptions
                ) || ''
              }
              titleClassName={`${css({
                color: palette.grey90,
              })}`}
              contentClassName={`${css({
                wordBreak: 'break-all',
                color: palette.grey70,
              })}`}
            />
            <Content
              enable={smartLink.appLinkProduct !== null}
              key={smartLink.appLinkProduct}
              title={
                'smart_links.overview.view_modal.which_item_this_should_link_to'
              }
              content={
                getLabelByValue(smartLink.appLinkProduct, productOptions) || ''
              }
              titleClassName={`${css({
                color: palette.grey90,
              })}`}
              contentClassName={`${css({
                wordBreak: 'break-all',
                color: palette.grey70,
              })}`}
            />
            <Content
              key={smartLink.mobileFallback}
              title={
                'smart_links.overview.view_modal.what_should_happen_on_a_mobile_device_if_the_customer_doesnt_have_the_app'
              }
              content={
                getLabelByValue(smartLink.mobileFallback, fallbackOptions) || ''
              }
              titleClassName={`${css({
                color: palette.grey90,
              })}`}
              contentClassName={`${css({
                wordBreak: 'break-all',
                color: palette.grey70,
              })}`}
            />
            <Content
              key={smartLink.desktopWebOrderingLocation}
              title={
                'smart_links.overview.view_modal.what_should_happen_on_a_desktop_device'
              }
              content={
                getLabelByValue(smartLink.desktopFallback, fallbackOptions) ||
                ''
              }
              titleClassName={`${css({
                color: palette.grey90,
              })}`}
              contentClassName={`${css({
                wordBreak: 'break-all',
                color: palette.grey70,
              })}`}
            />

            <Content
              key={smartLink.id}
              title={'smart_links.overview.view_modal.last_modified'}
              content={formatDateString(smartLink.updatedAt, 'abbreviation')}
              titleClassName={`${css({
                color: palette.grey90,
              })}`}
              contentClassName={`${css({
                wordBreak: 'break-all',
                color: palette.grey70,
              })}`}
            />
          </>

          <div className="d-flex justify-content-end align-items-center ">
            <Button
              size={Size.STANDARD}
              kind={Kind.MINIMAL}
              className={`mr-s`}
              onClick={exit}
            >
              <Translate
                value={'smart_links.overview.view_modal.buttons.cancel'}
              />
            </Button>

            <Button
              onClick={onEdit}
              kind={Kind.SECONDARY}
              size={Size.STANDARD}
              className={`mr-s ${css({
                borderWidth: '2px',
              })}`}
            >
              <Translate
                value={'smart_links.overview.view_modal.buttons.edit'}
              />
            </Button>
            <Button
              onClick={onCopyUrlClick}
              kind={Kind.PRIMARY}
              size={Size.STANDARD}
            >
              <Translate
                value={'smart_links.overview.view_modal.buttons.copy_url'}
              />
            </Button>
          </div>
        </Switch>
      </Modal.Body>
    </Modal>
  )
}

export default SmartLinkViewModal
