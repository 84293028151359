import { Text } from '@thanx/uikit/text'
import React from 'react'
import { Translate } from 'react-redux-i18n'

interface ContentProps {
  title: string
  content: string
  titleClassName?: string
  contentClassName?: string
  enable?: boolean
}

const Content: React.FC<ContentProps> = ({
  title,
  content,
  titleClassName = '',
  contentClassName = '',
  enable = true,
}) => {
  if (!enable) {
    return null
  }
  return (
    <div className="d-flex flex-column justify-content-center align-items-start mb-m">
      <Text.BodyText3 className={`grey90 mb-2 ${titleClassName}`}>
        <Translate value={title} />
      </Text.BodyText3>
      <Text.BodyText4 className={`grey70 ${contentClassName} break-all`}>
        {content}
      </Text.BodyText4>
    </div>
  )
}

export default Content
