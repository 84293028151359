import { FormsyInput } from '@thanx/uikit/input'
import { Text } from '@thanx/uikit/text'
import Section from 'components/Section'
import { buildTranslate } from 'locales'
import { FormModel } from 'models/smartLink'
import React from 'react'

const t = buildTranslate('cms.settings.smart_links')

type NameSectionProps = {
  formModel: FormModel
}

const NameSection: React.FC<NameSectionProps> = ({ formModel }) => (
  <Section className="p-m mt-l">
    <Text.Header4 tag="div" bold>
      {t('link_name_label')}
    </Text.Header4>
    <Text.BodyText3
      tag="div"
      color="grey70"
      className="mt-xs font-weight-normal"
    >
      {t('name_description')}
    </Text.BodyText3>
    <FormsyInput
      name="name"
      className="mt-m"
      label={t('name_label')}
      value={formModel.name || ''}
      placeholder={t('name_placeholder')}
      validations={{
        isRequired: true,
      }}
      validationErrors={{
        isRequired: t('required'),
      }}
    />
  </Section>
)

export default NameSection
