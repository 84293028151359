import { storedValueApi } from 'api/storedValue'
import Loading from 'components/Loading'
import PageHeader from 'components/PageHeader'
import TabItem from 'components/Tabs/TabItem'
import TabNav from 'components/Tabs/TabNav'
import TabPane from 'components/Tabs/TabPane'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import useFlag from 'hooks/useFlag'
import { buildTranslate } from 'locales'
import { useState } from 'react'
import Tab from 'react-bootstrap-five/Tab'
import Helmet from 'react-helmet'
import { useAbility } from 'utilities/ability'
import EmptyState from './EmptyState'
import StoredValueActivity from './StoredValueActivity'
import StoredValueEngagement from './StoredValueEngagement'

const t = buildTranslate('stored_value')

type TabName = 'activity' | 'engagement'

const StoredValue = () => {
  const ansaReportsEnabled = useFlag('display-ansa-reports', false)

  const canAccessAnsaReports = useAbility().can('access', 'AnsaReports')
  const merchant = useCurrentMerchant()

  const displayAnsaReports = ansaReportsEnabled && canAccessAnsaReports
  const shouldNotDisplayPage = !displayAnsaReports || !merchant

  const [activeTab, setActiveTab] = useState<TabName>('activity')

  const { data, isLoading } = storedValueApi.useGetConfigQuery(undefined, {
    skip: shouldNotDisplayPage,
  })

  const storedValueActivityPath = 'dashboards/367'
  const storedValueActivityHeight = 1330
  const storedValueEngagementPath = 'dashboards/373'
  const storedValueEngagementHeight = 1330

  if (shouldNotDisplayPage) return null

  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>
      <PageHeader title={t('title')}>
        <Tab.Container
          activeKey={activeTab}
          transition={false}
          onSelect={(tab: string | null) => {
            if (!tab) return
            setActiveTab(tab as TabName)
          }}
        >
          <TabNav>
            <TabItem state={'activity'} title={t('tabs.activity')} />
            <TabItem state={'engagement'} title={t('tabs.engagement')} />
          </TabNav>
          <Tab.Content className="h-100 grey-05-bkg">
            {isLoading && <Loading />}

            {!isLoading && data?.state === 'inactive' && <EmptyState />}
            {!isLoading && data?.state === 'active' && (
              <>
                <TabPane state={'activity'}>
                  <StoredValueActivity
                    canAccessAnsaReports={canAccessAnsaReports}
                    merchant={merchant}
                    storedValueActivityPath={storedValueActivityPath}
                    storedValueActivityHeight={storedValueActivityHeight}
                  />
                </TabPane>
                <TabPane state={'engagement'}>
                  <StoredValueEngagement
                    canAccessAnsaReports={canAccessAnsaReports}
                    merchant={merchant}
                    storedValueEngagementPath={storedValueEngagementPath}
                    storedValueEngagementHeight={storedValueEngagementHeight}
                  />
                </TabPane>
              </>
            )}
          </Tab.Content>
        </Tab.Container>
      </PageHeader>
    </>
  )
}

export default StoredValue
