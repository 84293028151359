export default {
  title: 'Smart Links',
  add_a_new_smart_link: 'Add a new smart link',
  no_smart_links_available_title: `You don't have any smart links yet`,
  description:
    'Link your customers to a page within your app. Add the smart link URL to your email, social media post, or any other digital touchpoint.',
  learn_more: 'Learn more',
  column_titles: {
    name: 'Name',
    last_modified: 'Last modified',
    url: 'URL',
    copy_url: 'Copy URL',
    actions: 'Actions',
  },
  actions: {
    edit: 'Edit',
    view_details: 'View details',
    delete: 'Delete',
  },
  external_form: {
    description: 'How to configure smart links',
  },
  link_copied_to_clipboard: 'Copied to clipboard',
  delete_modal: {
    title: 'Delete smart link',
    description: 'Are you sure you want to delete this smart link?',
    buttons: {
      cancel: 'Cancel',
      delete: 'Delete',
      copy_url: 'Copy URL',
      edit: 'Edit',
    },
  },
  view_modal: {
    url: 'URL',
    what_page_you_want_to_link_to_in_the_app:
      'What page you want to link to in the app',
    which_ordering_menu_you_want_to_link_to:
      'Which ordering menu you want to link to',
    which_item_this_should_link_to: 'Which item this should link to',
    what_should_happen_on_a_mobile_device_if_the_customer_doesnt_have_the_app: `What should happen on a mobile device if the customer doesn't have the app`,
    what_should_happen_on_a_desktop_device: `What should happen on a desktop device`,
    last_modified: 'Last modified',
    buttons: {
      cancel: 'Cancel',
      edit: 'Edit',
      copy_url: 'Copy URL',
    },
  },
  create_smart_link: {
    navbar: {
      title: 'SMART LINKS',
      subtitle: 'New smart link',
      cancel: 'Cancel',
      save_and_return_to_smart_links: 'Save & return to smart links',
      return_to_smart_links: 'Return to smart links',
    },
    form: {
      link_name_label: 'Link name',
      name_label: 'Name',
      name_placeholder: 'Enter a name for the smart link',
      name_description:
        'How this link will appear in your custom links list. This name is used to help you stay organized, customers will not see this.',
      configuration_label: 'Configuration',
      description_1:
        'Link your customers to a page within your app. Add the smart link URL to your email, social media post, or any other customer-facing touchpoint.',
      description_2:
        'Customers that click on this link will be automatically redirected to the appropriate page in the app if they already have the app installed.',
      description_3: `Customers that don't have the app installed can be redirected to the app store or to your digital ordering website.`,
      what_page_you_want_to_link_to_in_the_app_label:
        'What page you want to link to in the app',
      which_ordering_menu_you_want_to_link_to_label:
        'Which ordering menu you want to link to',
      what_should_happen_on_a_mobile_device_if_the_customer_doesnt_have_the_app_label: `What should happen on a mobile device if the customer doesn't have the app`,
      what_should_happen_on_a_desktop_device_label: `What should happen on a desktop device`,
      save_and_generate_url_button: 'Save & Generate URL',
      smart_link_url_label: 'SMART LINK URL',
      copy_url_button: 'Copy URL',
      validations: {
        required: 'this field is required',
      },
      errors: {
        generate_error: 'Unable to generate link',
      },
    },
    alerts: {
      create_error:
        'There was an error creating your smart link. Please try again.',
    },
  },
}
