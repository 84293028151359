import { Button } from '@thanx/uikit/button'
import { Text } from '@thanx/uikit/text'
import Section from 'components/Section'
import Spinner from 'components/Spinner'
import Formsy from 'formsy-react'
import useProducts from 'hooks/useProducts'
import { buildTranslate } from 'locales'
import { FormModel } from 'models/smartLink'
import React from 'react'
import { useUniqueMenuCategories } from 'scenes/Cms/Content/AppHomePage/hooks'
import AppFields from './AppFields'
import ConfigurationText from './ConfigurationText'
import NameSection from './NameSection'
import WebFields from './WebFields'
const t = buildTranslate('cms.settings.smart_links')

type Props = {
  onSubmit?: (model: FormModel) => Promise<void>
  enableSmartPageContent?: boolean
  onFormChange?: () => void
  generateButtonLabel?: string
  isNewSmartLinkCreated?: boolean
  onFormValid?: () => void
  onFormInvalid?: () => void
  isGenerateButtonDisabled?: boolean
  formModel?: FormModel
  linkGenerationFormModel: FormModel
  setLinkGenerationFormModel: (model: FormModel) => void
  formRef?: React.RefObject<Formsy>
  enforceCustomUrlValidation?: boolean
}

const LinkGenerationForm = ({
  onSubmit,
  enableSmartPageContent,
  onFormChange,
  generateButtonLabel,
  isNewSmartLinkCreated,
  onFormValid,
  onFormInvalid,
  isGenerateButtonDisabled,
  formModel = {},
  linkGenerationFormModel,
  setLinkGenerationFormModel,
  formRef,
  enforceCustomUrlValidation = false,
}: Props) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false)

  const { products, isLoading: productsLoading } = useProducts()
  const productOptions = (products || []).map(product => ({
    label: product.name,
    value: product.name.toLowerCase().replace(/\s+/g, '+'),
  }))
  const { menuCategories, isLoading: menuCategoriesLoading } =
    useUniqueMenuCategories()
  const menuCategoryOptions = (menuCategories || []).map(category => ({
    label: category.name,
    value: category.slug,
  }))
  const isLoading = productsLoading || menuCategoriesLoading

  if (isLoading) {
    return (
      <Spinner
        className="text-center margin-top-huge"
        size="4x"
        isLoading={true}
      />
    )
  }

  async function handleSubmit(model: FormModel) {
    if (!onSubmit) return

    setIsSubmitting(true)
    await onSubmit(model)
    setIsSubmitting(false)
  }

  const handleChange = (model: FormModel) => {
    onFormChange?.()
    setLinkGenerationFormModel(model)
  }

  const configurationContent = enableSmartPageContent ? (
    <ConfigurationText />
  ) : null

  const nameSection = enableSmartPageContent ? (
    <NameSection formModel={formModel} />
  ) : null

  return (
    <Formsy
      onChange={handleChange}
      onValidSubmit={model => handleSubmit(model)}
      onValid={onFormValid}
      onInvalid={onFormInvalid}
      ref={formRef}
      role="form"
    >
      <div className="mb-m">
        {nameSection}
        <Section className="p-m mt-l">
          {configurationContent}
          <AppFields
            productOptions={productOptions}
            menuCategoryOptions={menuCategoryOptions}
            formModel={linkGenerationFormModel}
            initialFormModel={formModel}
          />
        </Section>
        <Section className="p-m mt-l">
          <Text.Header4 className="mb-m">{t('no_app.title')}</Text.Header4>
          <WebFields
            className="mb-m"
            name="mobile"
            label={t('no_app.mobile_label')}
            availableFallbacks={['ordering', 'custom', 'store']}
            productOptions={productOptions}
            menuCategoryOptions={menuCategoryOptions}
            formModel={linkGenerationFormModel}
            initialFormModel={formModel}
            enforceCustomUrlValidation={enforceCustomUrlValidation}
          />
          <WebFields
            name="desktop"
            label={t('no_app.desktop_label')}
            availableFallbacks={['ordering', 'custom']}
            productOptions={productOptions}
            menuCategoryOptions={menuCategoryOptions}
            formModel={linkGenerationFormModel}
            initialFormModel={formModel}
            enforceCustomUrlValidation={enforceCustomUrlValidation}
          />
        </Section>
      </div>
      <Button
        type={'submit'}
        isLoading={isSubmitting}
        disabled={isGenerateButtonDisabled || isNewSmartLinkCreated}
      >
        {generateButtonLabel || t('generate_link')}
      </Button>
    </Formsy>
  )
}

export default LinkGenerationForm
