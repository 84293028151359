import { Text } from '@thanx/uikit/text'
import { buildTranslate } from 'locales'
import React from 'react'

const t = buildTranslate('cms.settings.smart_links')

const ConfigurationText = () => {
  return (
    <>
      <Text.Header4 bold key="configuration_label">
        {t('configuration_label')}
      </Text.Header4>
      <Text.BodyText3
        tag="div"
        color="grey70"
        key="description_1"
        className="mt-xs font-weight-normal"
      >
        {t('description_1')}
      </Text.BodyText3>
      <Text.BodyText3
        tag="div"
        color="grey70"
        key="description_2"
        className="mt-xs font-weight-normal"
      >
        {t('description_2')}
      </Text.BodyText3>
      <Text.BodyText3
        tag="div"
        color="grey70"
        key="description_3"
        className="mb-m font-weight-normal"
      >
        {t('description_3')}
      </Text.BodyText3>
    </>
  )
}

export default ConfigurationText
