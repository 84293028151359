export default {
  titles: {
    dashboard: 'Dashboard',
    reviews: 'Program Reviews',
    customers: 'Your Customers',
    loyalty: 'Loyalty',
    loyalty_status: 'Loyalty Status',
    loyalty_results: 'Loyalty Results',
    feedback: 'Feedback',
    feedback_overview: 'Overview',
    comparative: 'Comparative Insights',
    insights: 'Insights',
    insights_overview: 'Overview',
    customer_lifecycle: 'Customer Lifecycle',
    top_members: 'Top Members',
    quartile: 'Quartile',
    frequency: 'Frequency',
    subscription: 'Email Subscription',
    promote: 'Promote',
    company: 'Company',
    access: 'Setup',
    access_overview: 'Overview',
    campaigns: 'Campaigns',
    results: 'Results',
    activity_report: 'Activity Report',
    signups: 'Signups',
    campaign_results: 'Campaign Results',
    feedback_results: 'Feedback (score card)',
    campaign_rewards: 'Rewards',
    rewards: 'Rewards',
    reward_templates: 'Reward templates',
    points: 'Points',
    cms: 'Brand & Content',
    dashboard_access: 'Dashboard access',
    ordering_conversion: 'Ordering conversion',
    average_check: 'Average check',
    engagement: 'Engagement',
    tiers: 'Tiers',
    help: 'Help',
    new: 'NEW',
  },
  coming_soon: 'Coming soon',
  new: 'New',
  beta: 'Beta',
  home: 'Home',
  profile_menu: {
    access: 'Dashboard access',
    billing: 'Billing',
    edit_profile: 'Edit profile',
    integrations: 'Integrations',
    locations: 'Locations',
    log_out: 'Log out',
    merchant_selector: {
      title: 'Switch merchant',
    },
    thanx_admin: 'Thanx admin: switch user',
    user_support: 'End-user support',
  },
  legacy: {
    reports: 'Legacy reports',
    features: 'Legacy features',
    learn_more: 'Learn more',
  },
  campaigns_menu: {
    button: 'Campaigns',
    overview: 'Overview',
    targeted: 'Targeted campaigns',
    automated: 'Automated campaigns',
    message: 'Message to everyone',
    reporting: 'Reporting',
    performance: 'Campaign performance',
    all_campaigns: 'All campaigns',
  },
  loyalty_menu: {
    button: 'Loyalty',
    points: 'Points',
    tiers: 'Tiers',
    loyalty_setup: 'Loyalty setup',
    exclusive_deals: 'Exclusive deals',
  },
  rewards_menu: {
    button: 'Rewards',
    rewards: 'Reward templates',
    rewards_accounting: 'Rewards accounting',
    reward_activity: 'Reward activity',
  },
  customers_menu: {
    button: 'Customers',
    sections: {
      manage_customers: {
        title: 'Manage customers',
        all_customers: 'All customers',
        segments: 'Segments',
      },
      activity: {
        title: 'Activity',
        daily_activity: 'Daily activity',
        purchases: 'Purchases',
        revenue: 'Revenue',
        signups: 'Signups',
      },
      customer_lifecycle: {
        title: 'Customer Lifecycle',
        activation: 'Activation',
        engagement: 'Engagement',
        retention: 'Retention',
        overview: 'Overview',
      },
      insights: {
        title: 'Insights',
        lifecycle: 'Customer lifecycle',
        capture_rate: 'Revenue capture rate',
        average_check: 'Average check',
        frequency: 'Frequency',
      },
      legacy: {
        activity_report: 'Activity report',
        top_members: 'Top members',
        quartile: 'Quartile',
        frequency: 'Frequency',
        import_email_subscribers: 'Import email subscribers',
      },
    },
  },
  feedback_menu: {
    button: 'Feedback',
    overview: 'Overview',
    respond: 'Respond to customer feedback',
    sentiment: 'Sentiment analysis',
    return_rate: 'Return rate',
    report_card: 'Report card',
  },
  experiences_menu: {
    button: 'Digital experiences',
    b_and_c: 'Brand & content',
    app_downloads: 'App downloads',
    ordering_management: 'Ordering management',
    ordering_conversion: 'Ordering conversion',
    smart_links: 'Smart links',
    stored_value: 'Stored Value',
  },
}
