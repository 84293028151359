import React from 'react'

type Props = {
  className?: string
  children?: React.ReactNode
  onClick?: () => void
}

const HeaderCell: React.FC<Props> = props => {
  const { className = '', children = null, onClick } = props

  return (
    <th className={`body-text-4 py-s px-m ${className}`} onClick={onClick}>
      {children}
    </th>
  )
}

export default HeaderCell
