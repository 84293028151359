import ProtectedRoute from 'components/ProtectedRoute'
import React from 'react'
import { Switch } from 'react-router'
import CreateSmartLink from './CreateSmartLink'
import EditSmartLink from './EditSmartLink.tsx'
import NewLanding from './Landing/Overview'

const SmartLinks: React.FC = () => {
  return (
    <Switch>
      <ProtectedRoute
        redirect="/"
        resource="SmartLinks"
        exact
        path="/smart_links_overview"
        component={NewLanding}
      />
      <ProtectedRoute
        redirect="/"
        resource="SmartLinks"
        exact
        path="/smart_links_overview/new"
        component={CreateSmartLink}
      />
      <ProtectedRoute
        redirect="/"
        resource="SmartLinks"
        exact
        path="/smart_links_overview/edit/:id"
        component={EditSmartLink}
      />
    </Switch>
  )
}

export default SmartLinks
