import { useStyletron } from '@thanx/uikit/theme'
import { alert } from 'actions/flash'
import { RequestSmartLinkPayload, UpdateSmartLinkPayload } from 'api/smartLink'
import { smartLinksApi } from 'api/smartLink'
import Formsy from 'formsy-react'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import {
  emptyFormModel,
  FormModel,
  SmartLink,
  transformSmartLinkToFormModel,
} from 'models/smartLink'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { push } from 'react-router-redux'
import HelperSection from 'scenes/Cms/Content/AppHomePage/Content/EditBlock/HelperSection'
import GeneratedLink from 'scenes/Cms/Settings/SmartLinks/GeneratedLink'
import { generateUrl } from 'scenes/Cms/Settings/SmartLinks/helpers'
import LinkGenerationForm from 'scenes/Cms/Settings/SmartLinks/LinkGenerationForm'
import { selectCurrentMerchant } from 'selectors/merchant'
import { transformFormModelToPayload } from 'utilities/smartLink'
import { smartLinksHelpUrl } from 'utilities/urlUtils'
import SmartLinkNavbar from './SmartLinkNavbar'
type Props = {
  isEditMode?: boolean
  smartLink?: SmartLink | null
}
const t = buildTranslate('smart_links.overview.create_smart_link')
const tOverview = buildTranslate('smart_links.overview')

const CreateSmartLink: React.FC<Props> = ({
  isEditMode = false,
  smartLink = null,
}) => {
  const [css] = useStyletron()
  const merchant = useSelector(selectCurrentMerchant)
  const [isNewSmartLinkCreated, setIsNewSmartLinkCreated] = useState(false)
  const [isFormValid, setIsFormValid] = useState(false)
  const [isSaveButtonClicked, setIsSaveButtonClicked] = useState(false)
  const [smartLinkFormObject, setSmartLinkFormObject] = useState<FormModel>({
    ...emptyFormModel,
  })
  const [linkGenerationFormModel, setLinkGenerationFormModel] =
    React.useState<FormModel>({})
  const formRef = React.useRef<Formsy>(null)

  const dispatch = useDispatch()

  const [generatedLink, setGeneratedLink] = useState<string | null>(null)

  const [
    createSmartLink,
    {
      data: { smart_link: newSmartLink } = {
        smart_link: null,
      },
      isLoading: isCreating,
      isSuccess: isCreated,
      error: createError,
    },
  ] = smartLinksApi.useCreateSmartLinkMutation()

  const [
    updateSmartLink,
    {
      data: { smart_link: updatedSmartLink } = {
        smart_link: null,
      },
      isSuccess: isUpdated,
    },
  ] = smartLinksApi.useUpdateSmartLinkMutation()

  useEffect(() => {
    if (smartLink) {
      setSmartLinkFormObject(transformSmartLinkToFormModel(smartLink))
      setLinkGenerationFormModel(transformSmartLinkToFormModel(smartLink))
      if (isEditMode) {
        setGeneratedLink(smartLink.url)
      }
    }
  }, [smartLink, isEditMode])

  useEffect(() => {
    if (isEditMode && updatedSmartLink) {
      setGeneratedLink(updatedSmartLink.url)
    }
  }, [isEditMode, updatedSmartLink])

  useEffect(() => {
    if (updatedSmartLink) {
      const link = updatedSmartLink?.url
      setGeneratedLink(link ?? null)
      // setIsNewSmartLinkCreated(true)
    }
  }, [updatedSmartLink])

  useEffect(() => {
    if (isCreated) {
      const link = newSmartLink?.url

      setGeneratedLink(link ?? null)
      setIsNewSmartLinkCreated(true)
    }
  }, [isCreated, newSmartLink])

  useEffect(() => {
    if (createError) {
      setIsSaveButtonClicked(false)
      dispatch(
        alert({
          key: 'danger',
          message: t('alerts.create_error'),
          timeout: 5,
        })
      )
    }
  }, [createError])

  useEffect(() => {
    if (isSaveButtonClicked && (isCreated || isUpdated)) {
      dispatch(push('/smart_links_overview'))
      setIsSaveButtonClicked(false)
    }
  }, [isSaveButtonClicked, isCreated, isUpdated])

  async function handleSubmit(model: FormModel) {
    if (!merchant) {
      return
    }

    setGeneratedLink(null)
    const baseUrl = generateUrl(model, {
      merchantHandle: merchant.handle,
    })

    if (!isEditMode) {
      const payload: RequestSmartLinkPayload = transformFormModelToPayload(
        model,
        baseUrl
      )
      await createSmartLink({ smart_link: payload })
      return
    }

    if (isEditMode && smartLink) {
      const payload: UpdateSmartLinkPayload = {
        ...transformFormModelToPayload(model, baseUrl),
        id: smartLink?.id ?? 0,
      }
      await updateSmartLink(payload)
      return
    }
  }

  const handleFormChange = () => {
    if (!isEditMode) {
      setGeneratedLink(null)
      setIsNewSmartLinkCreated(false)
    }
  }

  const handleSave = async () => {
    if (isNewSmartLinkCreated) {
      handleCancel()
      return
    }
    setIsSaveButtonClicked(true)
    formRef.current?.submit()
    // await handleSubmit(linkGenerationFormModel)
  }

  const handleCancel = () => {
    dispatch(push('/smart_links_overview'))
  }

  const handleFormValid = () => {
    setIsFormValid(true)
  }

  const handleFormInvalid = () => {
    setIsFormValid(false)
  }

  return (
    <div
      data-testid="create-smart-link"
      className={`${css({
        minHeight: '100%',
      })} grey-05-bkg`}
    >
      <div>
        <div
          className={`${css({
            marginTop: '30px',
          })}`}
        >
          <SmartLinkNavbar
            title={t('navbar.title')}
            subtitle={linkGenerationFormModel.name || ''}
            onCancel={handleCancel}
            onSave={handleSave}
            isSaving={isCreating}
            isNewSmartLinkCreated={isNewSmartLinkCreated}
            isFormValid={isFormValid}
          />
          <div
            className={`pt-m ${css({
              marginLeft: '5.375rem',
              marginRight: '5.375rem',
              paddingBottom: '3rem',
            })}`}
          >
            <div className="mb-l">
              <LinkGenerationForm
                onSubmit={handleSubmit}
                enableSmartPageContent={true}
                onFormChange={handleFormChange}
                generateButtonLabel={t('form.save_and_generate_url_button')}
                isNewSmartLinkCreated={isNewSmartLinkCreated}
                onFormValid={handleFormValid}
                onFormInvalid={handleFormInvalid}
                isGenerateButtonDisabled={!isFormValid}
                formModel={smartLinkFormObject}
                linkGenerationFormModel={linkGenerationFormModel}
                setLinkGenerationFormModel={setLinkGenerationFormModel}
                formRef={formRef}
                enforceCustomUrlValidation={true}
              />
            </div>
            <GeneratedLink generatedLink={generatedLink} />
            <div
              className={`${css({
                marginTop: '4rem',
                marginBottom: '4rem',
              })}`}
            >
              <HelperSection
                type="external_form"
                helpUrl={smartLinksHelpUrl}
                description={tOverview('external_form.description')}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateSmartLink
