import { Button } from '@thanx/uikit/button'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import { alert } from 'actions/flash'
import Bulb from 'components/Icons/Bulb'
import { buildTranslate } from 'locales'
import React from 'react'
import { useDispatch } from 'react-redux'

const t = buildTranslate('cms.settings.smart_links')

type Props = {
  generatedLink: string | null
}

const SmartLinks = ({ generatedLink }: Props) => {
  const [css] = useStyletron()
  const dispatch = useDispatch()

  const copyToClipboard = async () => {
    if (!generatedLink) return

    // Note that navigator.clipboard.writeText is only supported in secure contexts (https)
    await navigator.clipboard.writeText(generatedLink)
    dispatch(
      alert({
        key: 'success',
        message: t('link_copied_to_clipboard'),
        timeout: 5,
      })
    )
  }

  return (
    <div
      className="grey-10-bkg d-flex flex-row justify-content-start p-m"
      data-testid="generated-link"
    >
      <Bulb className="mr-s" />
      <div
        className={`max-width-90 ${css({
          wordWrap: 'break-word',
        })}`}
      >
        <Text.BodyText6
          tag="div"
          className={`mb-s text-uppercase ${css({
            letterSpacing: '0.125rem',
          })}`}
        >
          {t('generated_link_title')}
        </Text.BodyText6>
        <Text.BodyText4
          tag="div"
          color="grey70"
          className="generated-smart-link"
        >
          {Boolean(generatedLink)
            ? generatedLink
            : t('generate_url_instructions')}
        </Text.BodyText4>
        <Button
          kind="secondary"
          className="mt-m"
          type={'submit'}
          isLoading={false}
          disabled={!Boolean(generatedLink)}
          onClick={copyToClipboard}
        >
          {t('copy_url')}
        </Button>
      </div>
    </div>
  )
}

export default SmartLinks
