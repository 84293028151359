import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import { alert } from 'actions/flash'
import { smartLinksApi } from 'api/smartLink'
import Cell from 'components/Cell'
import { formatDateString } from 'components/PointsMultiplierCard/helpers'
import palette from 'constants/palette'
import { buildTranslate } from 'locales'
import { SmartLink } from 'models/smartLink'
import { useState } from 'react'
import { Button } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import SmartLinkActionMenu from './SmartLinkActionMenu'
import SmartLinkDeleteModal from './SmartLinkDeleteModal'
import SmartLinkViewModal from './SmartLinkViewModal'

const t = buildTranslate('smart_links.overview')

type Props = {
  smartLink: SmartLink
}

const SmartLinkItem = ({ smartLink }: Props) => {
  const [css] = useStyletron()
  const dispatch = useDispatch()
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showViewModal, setShowViewModal] = useState(false)
  const [archiveSmartLink, { isLoading: isArchiving }] =
    smartLinksApi.useArchiveSmartLinkMutation()

  const triggerDelete = async () => {
    await archiveSmartLink(smartLink.id)
  }

  const copyToClipboard = async () => {
    if (!smartLink.url) return

    // Note that navigator.clipboard.writeText is only supported in secure contexts (https)
    await navigator.clipboard.writeText(smartLink.url)
    dispatch(
      alert({
        key: 'success',
        message: t('link_copied_to_clipboard'),
        timeout: 5,
      })
    )
  }
  const onDeleteBtnClick = () => {
    setShowDeleteModal(true)
  }
  const onViewBtnClick = () => {
    setShowViewModal(true)
  }
  const onCopyUrlClick = async () => {
    setShowViewModal(false)
    await copyToClipboard()
  }

  return (
    <>
      <tr className="border-bottom-1 grey-30-border" key={smartLink.updatedAt}>
        <Cell key={smartLink.name}>
          <Text.BodyText4
            color={palette.grey70}
            tag="div"
            className={css({
              width: '186px',
            })}
          >
            {smartLink.name}
          </Text.BodyText4>
        </Cell>
        <Cell
          key={smartLink.url}
          className={css({
            width: '574px',
          })}
        >
          <Text.BodyText4
            color={palette.grey70}
            tag="div"
            className={css({
              width: '574px',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            })}
          >
            {smartLink.url}
          </Text.BodyText4>
        </Cell>
        <Cell key={smartLink.updatedAt}>
          <Text.BodyText4 color={palette.grey70}>
            {formatDateString(smartLink.updatedAt, 'abbreviation')}
          </Text.BodyText4>
        </Cell>
        <Cell key={'actions'}>
          <Text.BodyText4 color={palette.grey70}>
            <SmartLinkActionMenu
              smartLinkId={smartLink.id}
              onDelete={onDeleteBtnClick}
              onView={onViewBtnClick}
            />
          </Text.BodyText4>
        </Cell>
        <Cell key={'copy_url'}>
          <Button
            bsStyle="link"
            type="submit"
            disabled={!smartLink.url}
            onClick={copyToClipboard}
          >
            {t('column_titles.copy_url')}
          </Button>
        </Cell>
      </tr>
      <SmartLinkDeleteModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        triggerDelete={triggerDelete}
        isLoading={isArchiving}
      />
      <SmartLinkViewModal
        show={showViewModal}
        onHide={() => setShowViewModal(false)}
        smartLink={smartLink}
        onCopyUrlClick={onCopyUrlClick}
        availableFallbacks={['ordering', 'custom', 'store']}
      />
    </>
  )
}

export default SmartLinkItem
