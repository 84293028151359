import { Button, Kind, Size } from '@thanx/uikit/button'
import React from 'react'
import { Button as BsButton, Modal } from 'react-bootstrap'
import { Translate } from 'react-redux-i18n'
import { useStyletron } from 'styletron-react'
type Props = {
  triggerDelete: () => Promise<void>
  isLoading: boolean
  show: boolean
  onHide: () => void
}

const SmartLinkDeleteModal: React.FC<Props> = ({
  triggerDelete,
  show,
  onHide,
  isLoading,
}) => {
  const [css] = useStyletron()

  const exit = () => {
    onHide()
  }

  const onDelete = async () => {
    await triggerDelete()
    onHide()
  }

  return (
    <Modal className="fs-unmask" show={show} onHide={onHide} zIndex={1000}>
      <Modal.Body>
        <div className="clearfix">
          <div className="pull-right">
            <BsButton
              onClick={onHide}
              className="btn-borderless padding-right-none"
              data-testid="close-button"
            >
              <span className="font-size-40 line-height-30 grey-60 light">
                &times;
              </span>
            </BsButton>
          </div>
        </div>

        <Translate
          data-testid="title"
          value={'smart_links.overview.delete_modal.title'}
          tag="h6"
        />

        <Translate
          tag="div"
          className="width-75 margin-bottom-huge grey-70"
          value={'smart_links.overview.delete_modal.description'}
          data-testid="description"
        />
        <div className="d-flex justify-content-end align-items-center ">
          <Button
            size={Size.STANDARD}
            kind={Kind.SECONDARY}
            data-testid="cancel-button"
            className={`mr-s ${css({
              borderWidth: '2px',
              // fontWeight: 'normal',
            })}`}
            onClick={exit}
          >
            <Translate
              value={'smart_links.overview.delete_modal.buttons.cancel'}
            />
          </Button>

          <Button
            onClick={onDelete}
            kind={Kind.PRIMARY}
            size={Size.STANDARD}
            isLoading={isLoading}
            data-testid="delete-button"
            className={css({
              // fontWeight: 'normal',
            })}
          >
            <Translate
              value={'smart_links.overview.delete_modal.buttons.delete'}
            />
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default SmartLinkDeleteModal
