export default {
  title: 'Smart links',
  description:
    'Link your customers to a page within your app. Add the smart link URL to your email, social media post, or any other digital touchpoint.<br/><br/>Customers that click on this link will be automatically redirected to the appropriate page in the app if they already have the app installed. Customers that don’t have the app installed can be redirected to the app store or to your digital ordering website.',
  description_1:
    'Link your customers to a page within your app. Add the smart link URL to your email, social media post, or any other customer-facing touchpoint.',
  description_2:
    'Customers that click on this link will be automatically redirected to the appropriate page in the app if they already have the app installed.',
  description_3: `Customers that don't have the app installed can be redirected to the app store or to your digital ordering website.`,
  configuration_label: 'Configuration',
  link_name_label: 'Link name',
  name_label: 'Name',
  name_placeholder: 'Enter a name for the smart link',
  name_description:
    'How this link will appear in your custom links list. This name is used to help you stay organized, customers will not see this.',
  learn_more_text: 'Learn more about smart links',
  generated_link_title: 'Smart Link URL',
  generate_url_instructions:
    'Click the generate URL button above and your smart link will appear here',
  generate_link: 'Generate URL',
  copy_url: 'Copy URL',
  link_copied_to_clipboard: 'Copied to clipboard',
  required: 'this field is required',
  invalid_url: 'Please enter a valid URL',

  errors: {
    generate_error: 'Unable to generate link',
  },

  app_link_and_fallbacks: {
    title: 'Where you want to link your customers',
    app_link_label: 'What page you want to link to in the app',
    app_link_category_label: 'Which ordering menu you want to link to',
    app_link_category_instructions:
      'If this menu is not available at the customer’s selected location, they will be redirected to the home page of the app.',
    app_link_product_label: 'Which item this should link to',
    app_link_product_instructions:
      'If this item is not available at the customer’s selected location, they will be redirected to the home page of the app.',

    labels: {
      about: 'About',
      account: 'Account',
      homePage: 'Home page',
      locations: 'Locations',
      notifications: 'Notifications',
      nps: 'Feedback',
      orderHistory: 'Order history',
      orderingMenu: 'Ordering menu',
      productDetails: 'Item on the ordering menu',
      registeredCards: 'Registered cards',
      rewards: 'Rewards',
      support: 'Support',
      earn: 'Check in / Earn',
    },
  },

  no_app: {
    title: 'What should happen if the app is not available',
    desktop_label: 'On a desktop device',
    mobile_label:
      'On a mobile device if the customer doesn’t have the app installed',
  },

  web_fallback: {
    fallback_labels: {
      app_store: 'Redirect to the app store',
      custom_url: 'Redirect to a custom URL',
      web_ordering: 'Redirect to the web ordering experience',
    },
    labels: {
      select_ordering_location: 'Select a web ordering location',
      select_category: 'Select a menu category',
      select_product: 'Select an item',
    },
    custom_url_placeholder: 'Enter custom URL...',
    category_placeholder: 'Select a menu category...',
    product_placeholder: 'Select an item...',

    web_ordering_locations: {
      home_page: 'Home page',
      rewards: 'Rewards',
      account: 'Account',
      saved_cards: 'Saved cards',
      order_history: 'Order history',
      ordering_menu: 'Ordering menu',
      items_on_the_menu: 'Item on the menu',
    },
  },
}
