import { Text } from '@thanx/uikit/text'
import sample from 'assets/images/stored-value/sample.png'
import { buildTranslate } from 'locales'
import { Container } from 'react-bootstrap-five'

const t = buildTranslate('stored_value.empty_state')

const EmptyState = () => {
  return (
    <Container className="pt-l">
      <Text.Header3 className="mb-m">{t('title')}</Text.Header3>
      <div className="pb-m">
        <Text.BodyText4>{t('description1')}</Text.BodyText4>
      </div>
      <div className="pb-m">
        <Text.BodyText4>{t('description2')}</Text.BodyText4>
      </div>
      <div className="pb-m">
        <Text.BodyText4>
          <a
            href={`mailto:merchant.support@thanx.com?subject=${t(
              'email_subject'
            )}`}
          >
            {t('contact_us')}
          </a>
        </Text.BodyText4>
      </div>

      <div className="pt-s text-center belize-hole-10-bkg">
        <img
          src={sample}
          alt="Sample app"
          style={{ height: '500px', width: 'auto' }}
        />
      </div>
    </Container>
  )
}

export default EmptyState
