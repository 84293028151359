import { api } from './index'

export type StoredValueConfig = {
  state: 'active' | 'inactive'
  card_image: StoredValueMerchantConfigImage | null
  card_icon_image: StoredValueMerchantConfigImage | null
  reload_presets: StoredValueMerchantConfigReloadPreset[]
  maximum_auto_reload_amount: number
  minimum_auto_reload_amount: number
  minimum_auto_reload_threshold: number
}

type StoredValueMerchantConfigReloadPreset = {
  amount: number
  bonus: number
}
type StoredValueMerchantConfigImage = {
  small: string
  medium: string
  large: string
}

export type StoredValueUser = {
  provider_id: string
  enrolled: boolean
  balance: number
}

export type StoredValueTransaction = {
  id: string
  amount: number
  kind: 'auto_reload' | 'add' | 'use' | 'refund' | 'bonus'
  created_at: string
  provider_transaction_url: string
}

export const storedValueApi = api.injectEndpoints({
  endpoints: builder => ({
    getConfig: builder.query<StoredValueConfig, void>({
      query: () => 'stored_value/config',
      providesTags: [{ type: 'StoredValueMerchantConfig' }],
    }),
    getStoredValueUser: builder.query<StoredValueUser, string>({
      query: userId => `stored_value/users/${userId}`,
      providesTags: (_result, _error, userId) => [
        { type: 'StoredValueUser', id: userId },
      ],
    }),
    getTransactions: builder.query<
      { transactions: StoredValueTransaction[] },
      string
    >({
      query: userId => `stored_value/users/${userId}/transactions`,
      providesTags: (_result, _error, userId) => [
        { type: 'StoredValueTransaction', id: userId },
      ],
    }),
  }),
})
