import { Button } from '@thanx/uikit/button'
import { Switch } from '@thanx/uikit/switch'
import { useStyletron } from '@thanx/uikit/theme'
import TrigangleDownIcon from 'assets/icons/triangle_down.svg'
import TrigangleUpIcon from 'assets/icons/triangle_up.svg'
import HeaderCell from 'components/HeaderCell'
import Pagination from 'components/Pagination'
import { buildTranslate } from 'locales'
import { SmartLink } from 'models/smartLink'
import { useEffect, useState } from 'react'
import HelperSection from 'scenes/Cms/Content/AppHomePage/Content/EditBlock/HelperSection'
import {
  compareByLastModified,
  compareByName,
  getNumberOfPages,
  smartLinkPageSize,
} from 'utilities/smartLink'
import { smartLinksHelpUrl } from 'utilities/urlUtils'
import SmartLinkItem from './SmartLinkItem'

type Props = {
  smartLinks: SmartLink[]
  onAddNewSmartLinkClick: () => void
}

const t = buildTranslate('smart_links.overview')
const SmartLinksTable = ({ smartLinks, onAddNewSmartLinkClick }: Props) => {
  const [css] = useStyletron()

  const [currentPage, setCurrentPage] = useState(1)
  const [numberOfPages, setNumberOfPages] = useState(
    getNumberOfPages(smartLinks)
  )
  const [smartLinksToDisplay, setSmartLinksToDisplay] = useState<SmartLink[]>(
    []
  )

  const [sortBy, setSortBy] = useState<'name' | 'last_modified'>(
    'last_modified'
  )
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc')

  const handleSort = (column: 'name' | 'last_modified') => {
    if (sortBy === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
      return
    }
    setSortBy(column)
    setSortDirection('asc')
  }

  useEffect(() => {
    setNumberOfPages(getNumberOfPages(smartLinks))
  }, [smartLinks])

  useEffect(() => {
    const sortedSmartLinks = [...smartLinks].sort((a, b) => {
      if (sortBy === 'name') {
        return compareByName(a, b, sortDirection)
      }

      if (sortBy === 'last_modified') {
        return compareByLastModified(a, b, sortDirection)
      }
      return 0
    })

    setSmartLinksToDisplay(
      sortedSmartLinks.slice(
        (currentPage - 1) * smartLinkPageSize,
        currentPage * smartLinkPageSize
      )
    )
  }, [currentPage, smartLinks, sortBy, sortDirection])

  const onChangePage = (page: number) => {
    setCurrentPage(page)
  }
  const bodyContent = smartLinksToDisplay.length
    ? smartLinksToDisplay.map(smartLink => (
        <SmartLinkItem smartLink={smartLink} key={smartLink.id} />
      ))
    : null

  const showPaginationComponent = Boolean(
    smartLinksToDisplay.length && numberOfPages > 1
  )

  return (
    <>
      <table className="full-width campaign-table">
        <thead>
          <tr>
            <HeaderCell
              key={t('column_titles.name')}
              onClick={() => handleSort('name')}
              className={css({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              })}
            >
              <div>{t('column_titles.name')}</div>
              <div
                className={css({
                  display: 'flex',
                  flexDirection: 'column',
                })}
              >
                <img
                  src={TrigangleUpIcon}
                  alt="trigangle_up"
                  className={css({ width: '8px', height: '8px' })}
                />
                <img
                  src={TrigangleDownIcon}
                  alt="trigangle_down"
                  className={css({ width: '8px', height: '8px' })}
                />
              </div>
            </HeaderCell>
            <HeaderCell key={t('column_titles.url')}>
              {t('column_titles.url')}
            </HeaderCell>
            <HeaderCell
              key={t('column_titles.last_modified')}
              onClick={() => handleSort('last_modified')}
              className={css({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              })}
            >
              <div>{t('column_titles.last_modified')}</div>
              <div
                className={css({
                  display: 'flex',
                  flexDirection: 'column',
                })}
              >
                <img
                  src={TrigangleUpIcon}
                  alt="trigangle_up"
                  className={css({ width: '8px', height: '8px' })}
                />
                <img
                  src={TrigangleDownIcon}
                  alt="trigangle_down"
                  className={css({ width: '8px', height: '8px' })}
                />
              </div>
            </HeaderCell>
            <HeaderCell key={t('column_titles.actions')} />
            <HeaderCell key={t('column_titles.copy_url')} />
          </tr>
        </thead>
        <tbody data-testid="smart-links-table-body">{bodyContent}</tbody>
      </table>
      <div className="d-flex justify-content-end align-items-center">
        <Button
          kind="secondary"
          onClick={onAddNewSmartLinkClick}
          data-testid="smart-links-table-add-new-smart-link-button"
          className="mr-0 mt-m mb-s mb-sm-0"
        >
          {t('add_a_new_smart_link')}
        </Button>
      </div>

      <Switch condition={showPaginationComponent}>
        <div className={'d-flex justify-content-center margin-top-huge'}>
          <Pagination
            currentPage={currentPage}
            onChangePage={onChangePage}
            numPages={numberOfPages}
          />
        </div>
      </Switch>
      <div className="mt-l">
        <HelperSection
          type="external_form"
          helpUrl={smartLinksHelpUrl}
          description={t('external_form.description')}
        />
      </div>
    </>
  )
}

export default SmartLinksTable
