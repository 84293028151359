import { SmartLink } from 'models/smartLink'
import React from 'react'
import SmartLinksTable from './SmartLinksTable'

type Props = {
  smartLinks: SmartLink[]
  onAddNewSmartLinkClick: () => void
}

const SmartLinksListView = ({ smartLinks, onAddNewSmartLinkClick }: Props) => {
  return (
    <div className="container" data-testid="smart-links-list-view">
      <div
        className={`table-responsive margin-bottom-huge padding-bottom-huge`}
      >
        <SmartLinksTable
          smartLinks={smartLinks}
          onAddNewSmartLinkClick={onAddNewSmartLinkClick}
        />
      </div>
    </div>
  )
}

export default SmartLinksListView
