import { Button } from '@thanx/uikit/button'
import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import { smartLinksApi } from 'api/smartLink'
import Spinner from 'components/Spinner'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import { SmartLink } from 'models/smartLink'
import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap-five'
import { push } from 'react-router-redux'
import { getSmartLinksFromPayload } from 'utilities/smartLink'
import NoContent from './NoContent'
import SmartLinksListView from './SmartLinksListView'

const t = buildTranslate('smart_links.overview')

const Overview = () => {
  const [css] = useStyletron()
  const dispatch = useDispatch()

  const {
    data: { smart_links: smartLinksPayload } = { smart_links: [] },
    isLoading: isLoadingSmartLinks,
    isFetching: isFetchingSmartLinks,
    isSuccess: isSuccessSmartLinks,
  } = smartLinksApi.useGetSmartLinksQuery()

  const [smartLinks, setSmartLinks] = useState<SmartLink[]>(
    getSmartLinksFromPayload(smartLinksPayload)
  )

  useEffect(() => {
    if (isSuccessSmartLinks) {
      setSmartLinks(getSmartLinksFromPayload(smartLinksPayload))
    }
  }, [smartLinksPayload, isSuccessSmartLinks])

  const onAddNewSmartLinkClick = () => {
    dispatch(push('/smart_links_overview/new'))
  }

  const spinner = (
    <Spinner
      isLoading={isLoadingSmartLinks || isFetchingSmartLinks}
      size="4x"
      className="d-flex justify-content-center pt-xl"
    />
  )

  let content = <NoContent onAddNewSmartLinkClick={onAddNewSmartLinkClick} />
  if (smartLinks.length) {
    content = (
      <SmartLinksListView
        smartLinks={smartLinks}
        onAddNewSmartLinkClick={onAddNewSmartLinkClick}
      />
    )
  }

  return (
    <>
      <Container>
        <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center py-xl">
          <Text.Header2 className="m-0 mr-xl mb-m mb-sm-0">
            {t('title')}
          </Text.Header2>
          <div className="d-flex flex-column flex-sm-row">
            <Button
              onClick={onAddNewSmartLinkClick}
              data-testid="add-new-smart-link-button"
            >
              {t('add_a_new_smart_link')}
            </Button>
          </div>
        </div>
      </Container>
      <div
        className={`grey-05-bkg border-top-1 pb-xxl grey-20-border pt-xl ${css({
          minHeight: 'calc(100% - 75px)',
        })}`}
      >
        <Switch
          condition={!(isLoadingSmartLinks || isFetchingSmartLinks)}
          fallback={spinner}
        >
          {content}
        </Switch>
      </div>
    </>
  )
}

export default Overview
