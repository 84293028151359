import { RequestSmartLinkPayload, SmartLinkPayload } from 'api/smartLink'
import { FormModel, SmartLink } from 'models/smartLink'

export const smartLinkPageSize = 10
export const getNumberOfPages = (smartLinks: SmartLink[]): number => {
  if (!smartLinks || !smartLinks.length) return 0
  return Math.ceil(smartLinks.length / smartLinkPageSize)
}

export const transformSmartLinkPayloadToSmartLink = (
  smartLink: SmartLinkPayload
): SmartLink => {
  return {
    id: smartLink.id,
    name: smartLink.name,
    url: smartLink.url,
    merchantId: smartLink.merchant_id,
    state: smartLink.state,
    createdAt: smartLink.created_at,
    updatedAt: smartLink.updated_at,
    appLink: smartLink.app_link,
    appLinkCategory: smartLink.app_link_category,
    appLinkProduct: smartLink.app_link_product,
    desktopFallback: smartLink.desktop_fallback,
    desktopCustomUrl: smartLink.desktop_custom_url,
    desktopWebOrderingLocation: smartLink.desktop_web_ordering_location,
    desktopWebOrderingCategory: smartLink.desktop_web_ordering_category,
    desktopWebOrderingProduct: smartLink.desktop_web_ordering_product,
    mobileFallback: smartLink.mobile_fallback,
    mobileCustomUrl: smartLink.mobile_custom_url,
    mobileWebOrderingLocation: smartLink.mobile_web_ordering_location,
    mobileWebOrderingCategory: smartLink.mobile_web_ordering_category,
    mobileWebOrderingProduct: smartLink.mobile_web_ordering_product,
  }
}

export const getSmartLinksFromPayload = (
  smartLinks: SmartLinkPayload[]
): SmartLink[] => {
  if (!smartLinks || !smartLinks.length) return []
  return smartLinks.map(transformSmartLinkPayloadToSmartLink)
}

export const transformFormModelToPayload = (
  formModel: FormModel,
  url: string
): RequestSmartLinkPayload => {
  return {
    name: formModel.name ?? '',
    url: url || '',
    app_link: formModel.appLink ?? undefined,
    app_link_category: formModel.appLinkCategory ?? undefined,
    app_link_product: formModel.appLinkProduct ?? undefined,
    desktop_fallback: formModel.desktopFallback ?? undefined,
    desktop_custom_url: formModel.desktopCustomUrl ?? undefined,
    desktop_web_ordering_location:
      formModel.desktopWebOrderingLocation ?? undefined,
    desktop_web_ordering_category:
      formModel.desktopWebOrderingCategory ?? undefined,
    desktop_web_ordering_product:
      formModel.desktopWebOrderingProduct ?? undefined,
    mobile_fallback: formModel.mobileFallback ?? undefined,
    mobile_custom_url: formModel.mobileCustomUrl ?? '',
    mobile_web_ordering_location:
      formModel.mobileWebOrderingLocation ?? undefined,
    mobile_web_ordering_category:
      formModel.mobileWebOrderingCategory ?? undefined,
    mobile_web_ordering_product:
      formModel.mobileWebOrderingProduct ?? undefined,
  }
}
export const compareByName = (
  a: SmartLink,
  b: SmartLink,
  sortDirection: 'asc' | 'desc'
) => {
  return sortDirection === 'asc'
    ? a.name.localeCompare(b.name)
    : b.name.localeCompare(a.name)
}

export const compareByLastModified = (
  a: SmartLink,
  b: SmartLink,
  sortDirection: 'asc' | 'desc'
) => {
  return sortDirection === 'asc'
    ? new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime()
    : new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
}
