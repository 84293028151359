const storedValue = {
  title: 'Stored value',
  tabs: {
    activity: 'Activity',
    engagement: 'Engagement',
  },
  empty_state: {
    title: 'Stored value is not configured',
    description1:
      'This groundbreaking offering (reminiscent of Starbucks’ payment system) allows consumers to preload funds for future digital and in-store purchases, all without the need for additional hardware.',
    description2:
      'This innovation offers a host of benefits. It enhances customer retention and revenue by encouraging consumers to return for subsequent purchases. Plus, it ensures upfront cash flow for restaurants, as stored value balances become immediately available upon loading. Starbucks, a pioneer in this space, currently maintains a prepaid balance of approximately two billion dollars, showcasing the immense potential of such systems.',
    contact_us: 'Contact us to learn more',
  },
}

export default storedValue
