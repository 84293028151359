import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import EditNavbar from 'components/Navbar/EditNavbar'
import { buildTranslate } from 'locales'
import React from 'react'
import { Button } from 'react-bootstrap'
import ContentWithSave from 'scenes/Cms/Settings/TermsAndPolicies/ContentWithSave'

interface Props {
  title?: string
  subtitle?: string
  onCancel: () => void
  onSave: () => void
  isSaving?: boolean
  isNewSmartLinkCreated?: boolean
  isFormValid?: boolean
}

const t = buildTranslate('smart_links.overview.create_smart_link.navbar')

const SmartLinkNavbar: React.FC<Props> = ({
  title,
  subtitle,
  onCancel,
  onSave,
  isSaving = false,
  isNewSmartLinkCreated = false,
  isFormValid = false,
}) => {
  const [css] = useStyletron()

  const onPublish = () => {
    onSave()
  }

  const saveBtnText = isNewSmartLinkCreated
    ? 'smart_links.overview.create_smart_link.navbar.return_to_smart_links'
    : 'smart_links.overview.create_smart_link.navbar.save_and_return_to_smart_links'

  return (
    <EditNavbar
      title={
        <>
          <Text.SmallCaps3 tag="div" className="mb-xs" color="white">
            {title}
          </Text.SmallCaps3>
          <Text.Header4 tag="div" bold color="white">
            {subtitle}
          </Text.Header4>
        </>
      }
    >
      <Button
        bsSize="large"
        className="text-white font-normal mr-3"
        onClick={onCancel}
      >
        {t('cancel')}
      </Button>
      <Button
        className={`btn-inverted ${css({
          width: '252px',
        })}`}
        bsSize="large"
        onClick={onPublish}
        disabled={!isFormValid}
      >
        <ContentWithSave value={saveBtnText} isSaving={isSaving} />
      </Button>
    </EditNavbar>
  )
}

export default SmartLinkNavbar
