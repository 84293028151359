import useCurrentMerchant from 'hooks/useCurrentMerchant'
import useFlag from 'hooks/useFlag'
import { buildTranslate } from 'locales'
import React from 'react'
import { useAbility } from 'utilities/ability'
import NavLink from '../Bar/NavLink'

const t = buildTranslate('nav.experiences_menu')

type PropsT = {
  onClick?: () => void
  isLeftNav?: boolean
}

const ExperiencesContent: React.FC<PropsT> = ({
  isLeftNav = false,
  onClick = () => {},
}) => {
  const merchant = useCurrentMerchant()
  const ability = useAbility()
  const showOrderingManagement = useFlag('mx-4-ordering-management', false)
  const ansaReportsEnabled =
    useFlag('display-ansa-reports', false) &&
    ability.can('access', 'AnsaReports')
  const orderingEnabled =
    merchant?.ordering_enabled &&
    ability.can('manage', 'OrderingManagement') &&
    (merchant?.ordering_provider === 'toast' ||
      merchant?.ordering_provider === 'olo')
  const toastOrderingEnabled =
    orderingEnabled && merchant?.ordering_provider === 'toast'

  const isReuseableSmartLinksEnabled = useFlag('reuseable-smart-links', false)

  return (
    <>
      <NavLink
        isLeftNav={isLeftNav}
        onClick={onClick}
        text={t('b_and_c')}
        link="/brand_content"
        disabled={ability.cannot('manage', 'CMS')}
      />
      <NavLink
        isLeftNav={isLeftNav}
        onClick={onClick}
        text={t('app_downloads')}
        link="/reports/app_downloads"
        disabled={ability.cannot('access', 'LocationReports')}
      />
      {showOrderingManagement && (
        <NavLink
          isLeftNav={isLeftNav}
          onClick={onClick}
          text={t('ordering_management')}
          link="/ordering_management"
          disabled={!toastOrderingEnabled}
        />
      )}
      <NavLink
        isLeftNav={isLeftNav}
        onClick={onClick}
        text={t('ordering_conversion')}
        link="/reports/ordering_conversion"
        disabled={!orderingEnabled}
      />
      {isReuseableSmartLinksEnabled && (
        <NavLink
          isLeftNav={isLeftNav}
          onClick={onClick}
          text={t('smart_links')}
          link="/smart_links_overview"
        />
      )}
      {ansaReportsEnabled && (
        <NavLink
          isLeftNav={isLeftNav}
          onClick={onClick}
          text={t('stored_value')}
          link="/stored_value"
        />
      )}
    </>
  )
}

export default ExperiencesContent
